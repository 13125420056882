<template>
    <div>
        <layout-header title=""></layout-header>
        <input v-show="LOCAL" id="deviceToken" placeholder="장치 토큰" readonly="readonly" type="text">
    </div>
</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader"
import $ from 'jquery'

export default {
    name: "naverRestReturn",
    components: {LayoutHeader},
    data() {

        const NAVER_RESPONSE_CODE = new URL(window.location.href).searchParams.get("code")
        const NAVER_RESPONSE_STATE = new URL(window.location.href).searchParams.get("state")

        const ENV = this.commonUtils.getEnv()

        const LOCAL = this.commonUtils.isLocal()

        return {

            BASE_URL: this.APIs.BASE_URL,

            NAVER_LOGIN_KEY: this.APIs.NAVER_LOGIN_KEY,
            NAVER_LOGIN_SECRET: this.APIs.NAVER_LOGIN_SECRET,
            NAVER_RESPONSE_CODE,
            NAVER_RESPONSE_STATE,

            ENV,
            deviceToken: '',
            LOCAL

        }
    },
    mounted() {

        this.login()
        this.commonUtils.deviceToken('#deviceToken')

    },
    methods: {

        goReferrer() {
            if (!this.stringUtils.isEmpty(this.$route.query.referrer)) {
                location.href = this.$route.query.referrer
            } else {
                location.href = "/"
            }
        },

        login() {

            const self = this

            $.ajax({

                url: self.APIs.OAUTH + '/naver',

                type: 'POST',

                data: {
                    url: 'https://nid.naver.com/oauth2.0/token?'
                        + 'grant_type=' + 'authorization_code' + '&'
                        + 'client_id=' + self.NAVER_LOGIN_KEY + '&'
                        + 'client_secret=' + self.NAVER_LOGIN_SECRET + '&'
                        + 'code=' + self.NAVER_RESPONSE_CODE + '&'
                        + 'state=' + self.NAVER_RESPONSE_STATE,
                    naverClientId: self.NAVER_LOGIN_KEY,
                    naverClientSecret: self.NAVER_LOGIN_SECRET
                },

                success: function (res) {

                    let snsId
                    let email

                    try {
                        snsId = res.result.response.id
                        email = res.result.response.email
                    } catch (e) {
                        self.commonUtils.localWarn(e)
                    }

                    self.commonUtils.localWarn("STATUS ::: " + res.status, "snsId : " + snsId, "email : " + email)

                    self.axiosCaller.get(self, self.APIs.SESSION + '/doSnsLogin', {
                        snsService: 'naver',
                        snsId: snsId,
                        email: email,
                        deviceToken: (self.webUtils.isDom('#deviceToken')) ? document.querySelector('#deviceToken').value : "",
                    }, async (res) => {

                        const result = res.data

                        if (result.status == 'duplicate') {

                            self.swalUtils.gritter("이미 가입된 계정입니다.", result.service + "으로 로그인 해 주세요.", "warning", 3000)
                                .then(t => {
                                    self.$router.push({
                                        name: "snsJoin"
                                    })
                                })

                        } else if (!result.coAccountVO.isUse) {
							self.swalUtils.fire("탈퇴된 계정입니다.", "관리자에게 문의바랍니다.", "info", null, null).then(
								r => {
									self.$router.push({
										name: "snsJoin"
									})
								}
							)
						} else {

                            if (await self.setMainSessionProc(result.coAccountVO)) {
                                self.setMainSessionKeep(self.isKeepSession)

                                await self.$router.push({
                                    name: "profile"
                                })

                            }

                        }

                    }, (e) => {
                        self.commonUtils.localErr(e)
                    })
                },
                fail: function (e) {
                    this.commonUtils.localErr(e)
                }

            })

        }

    }

}
</script>

<style scoped>

</style>